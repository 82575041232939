@import "app/javascript/core/variables";

.container {
  background-size: cover;
  background-position-y: top;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  height: 550px;
  position: relative;
  padding: 50px;
  opacity: 0.3;
  transition: opacity 200ms ease-in-out;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.8) 60%, #000000 100%);
  }

  &.isActive {
    opacity: 1;
  }

  @include lg {
    background-size: 130% auto;
  }

  @include md {
    padding: 30px;
    height: 520px;
    background-size: 125% auto;

    &:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 22%, rgba(0, 0, 0, 0.9) 50%, #000000 100%)
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  text-align: center;
}

.title {
  margin-bottom: 0.67em;
}

.description {
  margin-bottom: 1.67em;
}

