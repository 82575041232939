.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 55px;
  color: #8A8A8A;
}

.iconLink {
  color: inherit;
  width: 1em;
  padding: 0;
  transition: 150ms filter ease-out;

  &:hover {
    filter: brightness(1.5);
    color: inherit;
  }

  &:not(:last-child) {
    margin-right: 0.346em;
  }

  svg {
    display: block;
    width: 1em;
    height: 1em;
  }
}
