@import "core/variables";

.wrapper {
  background-color: $dark-grey;
}

.container {
  position: relative;
  padding: 50px 0;
  color: $white;

  hr {
    opacity: 1;
    margin: 0 0 30px;
  }

  @include sm {
    padding: 26px 0 50px;
  }
}

.backToTopButton {
  position: absolute;
  bottom: 50px;
  right: 4px;

  @include sm {
    top: 32px;
    bottom: initial;
  }
}

.menuContainer {
  width: max-content;
  margin-bottom: 50px;

  @include md {
    width: 100%;
  }

  @include sm {
    width: fit-content;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    gap: 50px;
  }
}

.footerContainer {
  display: flex;
  justify-content: space-between;
}

.columnTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;

  @include md {
    font-size: 20px;
  }
}

.columnList {
  list-style: none;
  padding: 0;

  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

.logo {
  width: 225px;

  @include md {
    width: 190px;
  }
}

.legal {
  font-size: 18px;

  @include sm {
    font-size: 14px;
  }
}
