@import "app/javascript/core/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}

.title {
  text-transform: capitalize;

  @include sm {
    text-align: center;
  }
}

.logos {
  display: flex;
  gap: 50px;

  @include lg {
    gap: initial;
  }

  @include md {
    flex-direction: column;
    max-width: 360px;
  }
}

.logo {
  width: 33.33%;
  transition: opacity 150ms ease-out;

  &:hover {
    opacity: 0.7;
  }

  img {
    width: 100%;
  }

  @include md {
    width: 100%;
  }
}

.promo {
  display: flex;
  gap: 75px;
  padding: 3.125rem;
  justify-content: center;
  align-items: center;
  background-color: $dark-grey;

  @include md {
    flex-direction: column;
    gap: 50px;
    text-align: center;
  }

  @include sm {
    padding: 1.875rem;
  }
}

.cpu {
  display: flex;
  flex-direction: column;
  font-size: 1.875rem;
  color: $white;
  white-space: nowrap;
}

.performance {
  @include gradientText;

  font-size: 6.875rem;
  font-weight: 700;
}
