@import "core/variables";

.container {
  display: flex;
  flex-direction: row;
  gap: 25px;

  @include md {
    gap: 15px;
  }
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  min-width: 110px;
  padding: 0 10px;
  height: 40px;
  text-align: center;

  &.events {
    background-color: $accent-1;
  }

  &.news {
    background-color: $accent-2;
  }

  @include md {
    min-width: 80px;
    height: 30px;
  }
}

.dateTag {
  border: 1px solid $white;
}
