@import "app/javascript/core/variables";

.content {
  padding: 3.125rem 0 9.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md {
    padding: 3rem 0 5rem;
  }
}

.title,
.subtitle {
  text-align: center;
}

.title {
  margin-bottom: 0.55em;
}

.subtitle {
  margin-bottom: 2.7em;
}

.possibilities {
  display: flex;
  flex-direction: column;
  gap: 150px;

  @include md {
    gap: 100px;
  }
}

