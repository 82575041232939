@import "app/javascript/core/variables";

.headerContainer {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.headerText {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
  }

  @include md {
    font-size: 20px
  }
}