@import "core/variables";

.button {
  padding: 0;
  border: none;
  background: none;
  font-size: 36px;

  &:hover {
    opacity: 0.7;
  }

  img {
    display: block;
    width: 1em;
    height: 1em;
  }
}
