@import "core/variables";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.125rem 1rem;
  border-radius: 20px;
  background-size: cover;
  text-align: center;
}

.logo {
}

.title {
  margin: 20px 0;
}

.description {
  margin-bottom: 2.08em;
}
