@import "./app/javascript/core/variables";

.container {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $white;
}

.heading {
  font-size: 60px;
  font-weight: bold;
  color: $white;
  margin-bottom: 0.5em;
  width: 100%;
}

.intro {
  font-size: 1.3em;
  font-weight: 300;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px $white solid;
}

.description {
  width: 100%;
  color: $white;
}

.button {
  @include buttonAnimationScale;

  font-size: 20px;
  font-weight: bold;
  line-height: 2em;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  padding: 0 2.5em;
  border-radius: 1.5em;
  color: $white;
  background: $gradient;
}

.actions {
  margin-top: 2.5em;
}

.goBack {
  @include button-outline;
  @include buttonAnimationScale;

  margin-left: 2.5em;
  color: $white;
  line-height: 2em;
  font-size: 20px;
  font-weight: 300;
  padding: 0 2.5em;
}

@include lg {
  .container {
    padding: 60px 0;
  }

  .heading {
    font-size: 52px;
  }
}

@include md {
  .heading {
    font-size: 44px;
  }

  .actions {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .goBack {
    margin-top: 1.5em;
    margin-left: 0;
  }
}
