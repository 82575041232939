@import "core/variables";

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 1.875rem 3.125rem;
  border-radius: 10px;
  background-size: cover;

  &::after {
    background: radial-gradient(21.06% 21.06% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    content: "";
    position: absolute;
    inset: 0;
  }

  * {
    z-index: 1;
  }

  @include md {
    padding: 3.5rem 1.5rem;
    flex-direction: column;
    text-align: center;
  }
}

.text {
  max-width: 820px;
}

.button {
  flex-shrink: 0;
}
