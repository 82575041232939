@import "core/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 75px;

  @include sm {
    gap: 50px;
  }
}

.blocks {
  display: flex;
  flex-direction: row;
  gap: 75px;

  @include sm {
    flex-direction: column;
    gap: 50px;
  }
}

.header {
  text-align: center;
}