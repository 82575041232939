@import "core/variables";

.popup {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  padding: 30px;
  overflow: auto;
  background-color: $dark-grey;
  box-shadow: 0 0 100px $black;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include md {
    gap: 25px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin-right: 1em;
}

.close {
  font-size: 30px;
}
