@import "app/javascript/core/variables";

.wrapper {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 54.41%, $black 100%);
  }
}

.container {
  min-height: 100vh;
  padding: 20rem 0 7rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  &.center {
    align-items: center;
    text-align: center;
  }

  &.left {
    align-items: flex-start;
    text-align: left;
  }

  @include lg {
    min-height: initial;
    padding: 25rem 0 5em;
  }

  @include md {
    padding: 30rem 0 2.1rem;
    text-align: center;
  }
}

.heading {
  margin-bottom: 0.43em;
}

.description {
  margin-bottom: 1.66em;
}
