@import "core/variables";

.button {
  border-radius: 5px;
  transition: 250ms background-color ease-in-out;

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
