@import "/app/javascript/core/variables";

.profile-panel {
  padding-bottom: 0;
  color: $white;
  display: flex;
  flex-direction: row;

  &__sign-out-button {
    width: 190px;
  }

  &__user-block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__username-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__username {
    font-size: 2.875rem;
    font-weight: 400;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__member-since {
    font-size: 18px;
    line-height: 1.67em;
    font-weight: 400;
    color: $grey;
  }

  &__member-since--mobile {
    display: none;
  }

  &__section-selectors {
    display: flex;
    flex-direction: row;
  }

  &__section-selector {
    font-size: 24px;
    font-weight: 400;
    background: none;
    border-bottom: 3px solid transparent;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    color: $white;
    white-space: nowrap;
    margin-top: 3.125rem;
    padding-bottom: 0.9375rem;
    transition: font-weight 150ms ease-out;

    &:not(:last-child) {
      margin-right: 3.125rem;
    }

    &--active {
      border-image: $gradient 1;
    }

    &:disabled {
      color: #717171;
    }

    &-first {
      width: 119px;
    }

    &-second {
      width: 148px;
    }

    &-third {
      width: 169px;
    }
  }

  @include sm {
    &__username-container {
      flex-direction: column;
      gap: 10px;
    }

    &__username {
      font-size: 1.875rem;
    }

    &__member-since {
      display: none;
    }

    &__member-since--mobile {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: $grey;
      margin-bottom: 1.875rem;
    }

    &__section-selectors {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.25rem;

      & > * {
        margin-top: 1.875rem;
        margin-bottom: 0;
      }
    }

    &__section-selector {
      padding-bottom: 0.625rem;
      font-size: 1.25rem;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
