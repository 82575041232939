@import "core/variables";

$gap: 75px;
$gap-sm: 50px;

.divider {
  opacity: 1;
  margin: $gap auto;
  color: $white;

  @include sm {
    margin: $gap-sm auto;
  }
}

.header {
  margin-top: 3em;
}

.filters {
  display: flex;
  gap: 50px;

  @include sm {
    gap: 30px;
  }
}

.newsfeedContainer {
  display: flex;
  gap: $gap;

  @include sm {
    gap: $gap-sm;
  }
}

.newsfeedCardContainer {
  flex-direction: column;
  padding-bottom: 5rem;

  @include sm {
    padding-bottom: 3.125rem;
  }
}

.newsfeedMediumCardContainer {
  padding-top: $gap;

  & > * {
    width: 50%;
  }

  @include sm {
    flex-direction: column;
    padding-top: $gap-sm;
    gap: $gap-sm;

    & > * {
      width: 100%;
    }
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  color: $white;
}
