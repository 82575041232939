@import "core/variables";

.content {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include lg {
    padding: 6rem 0;
  }
}

.title {
  text-align: center;
  margin-bottom: 1.38em;
}

.useCases {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @include xl {
    grid-gap: 20px;
  }

  @include lg {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

  @include sm {
    max-width: 380px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.useCase {
  min-height: 174px;

  img {
    margin-bottom: 30px;
  }
}
