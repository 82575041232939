@import "core/variables";

.item {
  color: $white;
  cursor: pointer;
  user-select: none;
}

.active {
  text-decoration: underline;
}