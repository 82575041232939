@import "./app/javascript/core/variables";

.job {
  display: flex;
  flex-direction: column;
  color: $white;
  font-size: 44px;
  margin-bottom: 35px;
}

.title {
  font-size: 1em;
  margin-bottom: 0.8em;
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
}

.button {
  @include buttonAnimationScale;

  font-size: 0.6em;
  font-weight: bold;
  line-height: 1.8em;
  padding: 0 1em;
  border-radius: 1em;
  color: $white;
  background: $gradient;
  margin-right: 1em;
}

@include lg {
  .job {
    font-size: 38px;
  }
}

@include md {
  .job {
    font-size: 32px;
  }
}
