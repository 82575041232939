@import "core/variables";

.content {
  text-align: center;
  padding: 3.125rem 0 5rem;
}

.title {
  margin-bottom: 0.54em;
}

.agreementButton {
  @include opacityAnimation;

  text-decoration: underline;
  cursor: pointer;
}

.products {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}
