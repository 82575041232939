@import "app/javascript/core/variables";

.carousel {
  width: 70%;
  overflow: visible;

  @include md {
    width: 100%;
    max-width: 420px;
  }
}

.bulletContainer {
  bottom: -35px !important;
}

.bullet {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #151515;
  border-radius: 50%;
  cursor: pointer;

  &.active {
    background-color: $white;
  }
}
