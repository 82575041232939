.socialIcon {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.75;
  }

  img {
    width: 40px;
    height: 40px;
  }
}