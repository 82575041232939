@import "core/variables";

.container {
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 150px;
  padding: 9rem 0;

  @include sm {
    gap: 50px;
    padding: 3.125rem 0;
  }
}

.bigCard {
  max-height: 405px;
}

.header {
  margin-bottom: 1.5625rem;
}
