@import "core/variables";

.content {
  padding: 8rem 0;

   @include lg {
    padding: 6rem 0;
  }
}

.title {
  text-align: center;
  margin-bottom: 0.5555em;
}

.subtitle {
  text-align: center;
  margin-bottom: 2.08em;
}
