@import "core/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &.textFirst {
    flex-direction: row-reverse;

    .textBlock {
      padding-left: 0;
    }
  }

  &:hover {
    img {
      transform: scale3d(1.02, 1.02, 1.02);
    }
  }

  @include lg {
    max-width: 600px;
    flex-direction: column;

    &.textFirst {
      flex-direction: column;
    }
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding: 0 6rem;

  @include xxl {
    padding: 0 4rem;
  }

  @include xl {
    padding: 0 3rem;
  }

  @include lg {
    padding: 0;
    width: 100%;
  }

  @include md {
    align-items: center;
  }
}

.title {
  margin-bottom: 1.4em;
  width: 100%;
}

.buttonLink {
  margin-top: 2em;
}

.imageBlock {
  overflow: hidden;
  width: 50%;

  img {
    transition: transform 250ms ease-in-out;
    width: 100%;
  }

  @include lg {
    width: 100%;
    margin-bottom: 50px;
  }
}
