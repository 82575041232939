@import "core/variables";

.mainWrapper {
  pointer-events: none;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: #010101;
  background-size: cover;
  background-position: right;

  border-radius: 20px;
  overflow: hidden;
  position: relative;
  max-height: 100%;
  min-height: 610px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  pointer-events: all;
  opacity: 0;
  transition: opacity 250ms ease-in;

  padding-top: 3.5rem;

  &.animate {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    pointer-events: none;
    inset: 0;
    z-index: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 37.5%, #000000 100%);
  }

  @include lg {
    min-height: initial;
  }
}

.content {
  max-height: 100%;
  overflow: auto;
  padding: 0 30px 4rem;
  z-index: 1;

  @include md {
    padding: 0 15px 4rem;
  }
}

.logo {
  max-width: 300px;
  padding-right: 50px;
  width: 100%;
}

.description {
  margin-top: 1.875rem;
}

.player {
  margin-top: 1.875rem;
  flex-shrink: 0;
}

.description,
.player {
  max-width: 600px;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 24px;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
  }

  @include md {
    top: 20px;
    right: 15px;
  }
}
