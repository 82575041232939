@import "core/variables";

.wrapper {
  background-color: $dark-grey;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
  padding: 7.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include md {
    padding: 0 0 3rem 0;
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      inset: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.9) 50%, #000000 100%)
    }
  }
}

.textBlock {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 2;

  @include md {
    width: 100%;
    max-width: 400px;
  }
}

.form {
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 600px;

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.input {
  border-color: $white;
  line-height: 1.5;

  @include md {
    line-height: 2.2;
  }
}

.error {
  position: absolute;
  top: 108%;
  color: $red-error;

  @include md {
    top: 40%;
    left: 0;
  }
}

.agreement {
  margin-top: 0.835em;
  max-width: 547px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  width: 80%;
  right: -15%;

  @include lg {
    right: -20%;
  }

  @include md {
    width: 120%;
    position: relative;
    inset: initial;
    margin-bottom: -55%;
  }
}

.underglow {
  width: 100%;
}

.newsletter {
  position: absolute;
  top: 30%;
  width: 38%;
  box-shadow: 100px 35px 150px 0 rgba(0, 0, 0, 0.75);

  @include md {
    top: 20%;
  }
}
