@import "core/variables";

.productVersionTable {
  border-spacing: 0 50px;
}

.cell1, .cell2, .cell3 {
  display: table-cell;
  line-height: 1rem;

  @include sm {
    display: inline-block;
  }
}

.cell1 {
  padding-right: 20px;
}

.cell2 {
  white-space: nowrap;
  min-width: 25%;
  padding-right: 20px;
}

.cell3 {
  white-space: nowrap;
  min-width: max-content;
  padding-right: 40px;
}

.cell4 {
  display: table-cell;

  @include sm {
    width: 100%;
    display: inline-block;
    padding-top: 20px;
  }
}

.downloadIcon {
  width: 29px;
  display: block;
}

.downloadLink {
  transition: opacity 150ms ease-out;

  &:hover {
    opacity: 0.7;
  }
}