@import "app/javascript/core/variables";

.flashModal {
  position: fixed;
  z-index: 105;
  top: calc($menu-height + 1em);
  right: 1em;
  margin: 0;
  font-size: 1rem;

  @include md {
    top: calc($menu-mobile-height + 1em);
  }
}
