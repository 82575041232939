@import "core/variables";

.panel {
  z-index: 1;
  width: 100%;
  background: $dark-grey;
  padding: 3.125rem;

  @include sm {
    padding: 1.875rem 1.25rem;
  }
}
