@import "core/variables";

.button {
  color: $black;
  background-color: $white;

  &:disabled {
    filter: initial;
  }

  &.notified,
  &:hover:not(:disabled) {
    background: $gradient;
    color: $white;
  }

  &.notified {
    border-image: $gradient 1;
    filter: none;
  }
}
