@import "core/variables";

.container {
  padding: 5.125rem 4.68rem;
  background-size: cover;
  background-position: center 0;
  min-height: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, transparent 90%);
  }

  @include lg {
    padding: 3.875rem 3rem;
    min-height: 500px;
  }

  @include md {
    padding: 3.875rem 1.25rem;
  }
}

.infoBlock {
  display: flex;
  flex-direction: column;
  gap: 50px;
  z-index: 1;
  width: 70%;
  max-width: 540px;

  @include md {
    width: 80%;
  }

  @include sm {
    width: 100%;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @include sm {
    align-items: flex-start;
    flex-direction: column;
  }
}
