@import "core/variables";

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @include md {
    gap: 30px;
  }
}

.button {
  width: 183px;
  align-self: flex-start;

  @include sm {
    align-self: center;
  }
}
