@import "app/javascript/core/variables";

.badge {
  position: fixed;
  z-index: 5;
  top: $menu-height;
  background: $gradient;

  @include md {
    top: $menu-mobile-height;
  }
}

.content {
  position: relative;
  padding: 0.45em 2em;
  text-align: center;

  a {
    @include opacityAnimation();

    text-decoration: underline;
    color: $white;
  }
}

.arrow {
  width: 0.75em;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5em;
}
