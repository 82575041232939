@import "app/javascript/core/core";

.instructions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;

  a {
    @include opacityAnimation;

    color: $white;
    text-decoration: underline;
  }

  @include lg {
    flex-direction: column;
  }
}

.instruction {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  overflow-wrap: break-word;
}

.requirements {
  margin: 50px 0;
  width: 100%;
  color: $white;

  a {
    @include opacityAnimation;

    color: inherit;
    text-decoration: underline;
  }
}


.requirementsLists {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 2em;

  ul {
    margin: 0.2em 0 0 0;
    padding-left: 1.4em;
  }

  @include lg {
    flex-direction: column;
    gap: 10px;
  }
}

.title {
  margin-bottom: 1em;
}
