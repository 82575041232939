@import "app/javascript/core/core";

.cases {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;

  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }

  @include md {
    grid-template-columns: repeat(1, 1fr);
    max-width: 400px;
  }
}

.case {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 0.49;
  width: 100%;
  padding-bottom: 1.5rem;

  * {
    z-index: 1;
  }

  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45.91%, #000000 100%);
  }

  @include lg {
    aspect-ratio: 2.3;
    padding-bottom: 1.5rem;
    background-position: center 55%;
  }
}

.title {
  padding: 0 10px;
}
