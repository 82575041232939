@import "core/variables";

.wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  height: $menu-height;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  transition-property: background-color;
  background-color: rgba(0, 0, 0, 0.10);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

  @include md {
    height: $menu-mobile-height;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-size: 1.25rem;
}

.logo {
  width: 225px;

  @include md {
    width: 190px;
  }
}

.button {
  font-size: 18px;
  height: 2.2222em;
  padding: 0 0.83em;

  @include md {
    font-size: 14px;
  }
}

.progress {
  position: fixed;
  width: 100%;
  top: $menu-height;
  z-index: 10;

  @include md {
    top: $menu-mobile-height;
  }
}
