@import "core/variables";

.wrapper {
  position: fixed;
  z-index: 102;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 1.3rem 0;

  @include md {
    flex-direction: column;
    justify-content: initial;
    align-items: center;
    gap: 20px;
  }
}

.textBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  max-width: 600px;

  @include md {
    align-items: center;
    width: 100%;
    max-width: 420px;
  }
}

.link {
  @include opacityAnimation;

  text-decoration: underline;
  color: inherit;
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  gap: 20px;

  @include md {
    width: 100%;
    max-width: 420px;
  }
}

.button {
  font-size: 1.125rem;
  line-height: 2.23;
  text-align: center;
  width: 120px;

  @include md {
    width: initial;
    flex: 1;
  }

  &.accept {
    @include transparentOutlineGradient(1px, $gradient);

    &:not(:hover) {
      @include gradientText;
    }

    &:hover {
      background: $gradient;
      color: $white;
    }
  }

  &.reject {
    border: 1px solid $white;
    color: $white;

    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}
