@import "core/variables";

.authTypeButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.authTypeButton {
  padding: 5px 0;
  border-bottom: 2px solid transparent;

  &.isActive {
    background: $gradient;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    border-image: $gradient 1;
  }
}

.verticalDivider {
  width: 0;
  height: 16px;
  margin: 0 16px;
  border: 1px solid $grey;
}

.oauthButtons {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  justify-content: space-between;

  @include md {
    gap: 30px;
  }

  @include sm {
    gap: 15px;
  }
}

.oauthButton {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.05);
  height: 50px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  img {
    margin-right: 0.82em;
    width: 35px;
  }

  @include md {
    height: 45px;

    img {
      width: 30px;
    }
  }
}

.orBlock {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    flex-shrink: 0;
    margin-right: 1em;
  }
}

.horizontalDivider {
  width: 100%;
  height: 0;
  border: 1px solid $white;
}
