@import "core/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 320px;
  background: $dark-grey;
  color: $white;
}

.headingBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0;

  h2 {
    margin-bottom: 0;
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.5;
  }
}

.close {
  font-size: 24px;
}

.contentBlock {
  padding: 40px 30px;

  p {
    margin-bottom: 0.5em;
    font-size: 18px;
  }
}

.buttonsBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px 30px;
}

.button {
  width: 120px;
}
