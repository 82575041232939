@import 'core/variables';

.progressContainer {
  width: 100%;
  height: 10px;
  background-color: $grey;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}

.progressBar {
  border-radius: inherit;
  height: 100%;
  background: $gradient;
  transition: width 0.1s linear;
} 