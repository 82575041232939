@import "core/variables";

.wrapper {
  padding: 7rem 0;
}

.container {
  padding: 3.5rem 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: url("shared/assets/cta.jpeg") no-repeat center right;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;

  @include md {
    padding: 0 0 3rem 0;
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  padding-left: 2em;
  width: 55%;

  @include md {
    width: 100%;
    z-index: 1;
    text-align: center;
    align-items: center;
    padding: 0 1em;
  }
}

.heading {
  margin-bottom: 0.43em;
}

.text {
  margin-bottom: 1.66em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  right: -15%;

  @include lg {
    right: -20%;
  }

  @include md {
    max-width: 650px;
    width: 150%;
    position: relative !important;
    inset: initial;
  }
}

.sdkImage {
  position: absolute;
  width: 57%;

  img {
    width: 100%;
  }

  @include md {
    position: initial;
    width: 85%;
  }
}
