@import "core/variables";

.container {
  width: 100%;

  @include md {
    padding: 20px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.title {
  margin-right: 1em;
}

.controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.playPauseButton {
  width: 24px;
  height: 24px;
  margin-right: 15px;

  img {
    width: 100%;
    height: 100%;
  }
}