.backToTopButton {
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.75;
  }
}