@use "core/variables" as coreVars;

.container {
  position: relative;
  background-color: coreVars.$black;
  min-width: 322px;
  min-height: 413px;
  z-index: 102;
}

.closeButton {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 15px;
  right: 15px;
}

.title {
  @include coreVars.gradientText;
  margin: 52px 36px 12px;
}

.content {
  margin: 0 36px;
  color: coreVars.$white;
}

.title,
.content {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.13px;
}

.flyingLaptops {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 30px;
  width: 169px;
  height: 137px;
}
