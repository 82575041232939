@import "core/variables";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;

  &:hover {
    img {
      transform: scale3d(1.02, 1.02, 1.02);
    }
  }

  @include sm {
    flex-direction: column;
    min-height: 0;
    gap: 30px;
  }
}

.imageBlock {
  width: 100%;
  max-width: 381px;
  overflow: hidden;

  img {
    transition: transform 150ms ease-in-out;
    width: 100%;
    object-fit: contain;
  }

  @include md {
    max-width: 190px;
    max-height: 190px;
  }

  @include sm {
    max-width: 100%;
    max-height: 100%;
  }
}

.infoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;

  @include sm {
    gap: 15px;
  }
}
