@import "core/variables";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include md {
    gap: 15px;
  }
}
