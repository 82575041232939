@import "app/javascript/core/variables";

.mainWrapper {
  margin-bottom: 6.25rem;
}

.content {
  padding: 6.67rem 0;
  position: relative;
  background-size: cover;
  background-position: center;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #101010 0.01%, rgba(16, 16, 16, 0) 72.43%);
  }
}

.block {
  padding: 0 3.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  width: 100%;
  max-width: 610px;
  position: relative;

  @include md {
    padding: 0 1.5rem;
  }
}

.title {
  margin-bottom: 0.43em;
}

.subtitle {
  margin-bottom: 2.1em;
}

.button {
  width: 183px;
}
