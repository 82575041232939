@import "app/javascript/core/variables";

.content {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include lg {
    padding: 6rem 0;
  }
}

.titlesBlock {
  text-align: center;
  margin-bottom: 3.125rem;
}

.subtitle {
  text-align: center;
  margin-top: 0.875em;
  max-width: 870px;
}
