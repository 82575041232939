@import "core/variables";

.alert {
  padding: 1em;
  border: 1px solid;
  border-radius: 0.375em;

  &.warning {
    border-color: $accent-2;
    background-color: $black;
    color: $white;
  }

  &.error {
    border-color: $red-error;
    background-color: #310000;
    color: $white;
  }

  &.withClose {
    padding-right: 2em;
    cursor: pointer;
  }
}

.closeButton {
  position: absolute;
  top: 2%;
  right: 0;
  font-size: 1.2em;
  margin: 0.3em;
}
