@import "app/javascript/core/variables";

.content {
  padding: 3.125rem 0 9.375rem;

  @include md {
    padding: 5rem 0 2rem;
  }
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
