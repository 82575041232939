@import "app/javascript/core/variables";

.textElement {
  @include roboto-condensed;

  color: $white;
  line-height: 1.15;
  margin-bottom: 0;
}

.h1 {
  font-size: 46px;
  font-weight: 400;
}

.h2 {
  font-size: 36px;
  font-weight: 400;
}

.h3 {
  font-size: 30px;
  font-weight: bold;
}

.text {
  font-size: 24px;
}

.textBold {
  font-size: 24px;
  font-weight: bold;
}

.subtext {
  font-size: 18px;
}

.superBigText {
  font-size: 125px;
  font-weight: bold;
}

@include md {
  .h1 {
    font-size: 30px;
  }

  .h2 {
    font-size: 24px;
  }

  .h3 {
    font-size: 22px;
  }

  .text {
    font-size: 20px;
  }

  .textBold {
    font-size: 20px;
  }

  .subtext {
    font-size: 14px;
  }

  .superBigText {
    font-size: 55px;
  }
}
