@import "app/javascript/core/variables";

$laptop-z-index: 2;

.content {
  padding: 12rem 0 6rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  text-align: center;

  @include md {
    gap: 30px;
    padding: 18rem 0 5rem;
  }
}

.productContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  width: 60%;

  @include md {
    width: 70%;
  }
}

.glow {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  width: 250%;
}

.title {
  font-size: clamp(50px, calc(2rem + 5vw), 125px);
  position: absolute;
  bottom: 90%;
  width: 250%;

  @include md {
    width: 150%;
    max-width: 580px;
  }
}

.laptop {
  z-index: $laptop-z-index;
  width: 100%;
}

.trombone,
.violin,
.clarinet {
  position: absolute;

  img {
    width: 100%;
  }
}

.trombone {
  z-index: $laptop-z-index - 1;
  right: 60%;
  bottom: 53%;
  width: 83.3%;
}

.violin {
  z-index: $laptop-z-index - 1;
  bottom: 65%;
  left: 70.5%;
  width: 43%;
}

.clarinet {
  z-index: $laptop-z-index + 1;
  bottom: 14%;
  left: 65%;
  width: 65.4%;
}

.text {
  margin-bottom: 0;
}
