@import "app/javascript/core/variables";

hr {
  opacity: 1;
  margin: 30px 0;
  color: $white;
}

.mobile-menu {
  position: fixed;
  width: 100%;
  top: $menu-height;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.10);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  opacity: 0;
  z-index: -1;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  padding: 18px 50px 46px 50px;

  @include md {
    padding: 20px;
    top: $menu-mobile-height;
  }

  &--active {
    opacity: 1;
    z-index: 102;
  }

  &__links {
    display: flex;
    gap: 50px;
    margin-bottom: 70px;

    @include sm {
      flex-direction: column;
      gap: 30px;
    }
  }

  &__bottom {
    display: none;

    @include sm {
      display: flex;
      margin-top: auto;
      flex-direction: column;
      gap: 30px;

      hr {
        margin: 0;
      }
    }
  }
}
