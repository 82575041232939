@import "./app/javascript/core/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.iconContainer {
  margin-right: 0.4em;

  svg {
    width: 0.8em;
    height: 0.8em;
  }
}

.element {
  font-size: 1em;
  font-weight: inherit;;
  margin-bottom: 0;
}

.center {
  justify-content: center;

  .hr {
    text-align: center;
  }
}

.left {
  justify-content: flex-start;

  .hr {
    text-align: left;

    &:before {
      content: none;
    }

    &:after {
      width: 100%;
    }
  }
}

.right {
  justify-content: flex-end;

  .hr {
    text-align: right;

    &:before {
      width: 100%;
    }

    &:after {
      content: none;
    }
  }
}

.hr {
  width: 100%;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 50%;
    vertical-align: middle;
    border-bottom: 2px solid #fff;
  }

  &:before {
    margin: 0 1em 0 -55%;
  }

  &:after {
    margin: 0 -55% 0 1em;
  }
}

.gradient {
  @include gradientText;
}
