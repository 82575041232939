@import "/app/javascript/core/variables";

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 8rem 0;
}

.title {
  @include sm {
    text-align: center;
  }
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 75px;

  @include lg {
    gap: 50px;
  }

  @include md {
    grid-template-columns: 1fr;
  }
}

.video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.videoMain {
  grid-column: 1 / -1;
}
