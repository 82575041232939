@import "core/variables";

.input {
  background: $black;
  color: $white;
  border: 1px solid transparent;
  width: 100%;
  font-size: 1.5rem;
  padding: 0 0.83em;
  line-height: 2.5;

  &::placeholder {
    color: $grey;
  }

  &:focus {
    border-color: $white;
    color: $white;
    box-shadow: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background: #676767;
  }

  &.isInvalid {
    border-color: $red-error;
  }

  &[type="file"] {
    padding-left: 0;
  }

  &::-webkit-file-upload-button,
  &::file-selector-button {
    appearance: none;
    color: $black;
    height: 100%;
    font-size: inherit;
  }

  @include md {
    font-size: 1.25rem;
    line-height: 2.25;
  }
}
