@import "normalize";
@import "variables";

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  user-select: none;
  white-space: nowrap;
  text-align: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

h1, h2, h3,
h4, h5, h6,
p {
  margin-top: 0;
}

a {
  text-decoration: none;
}

img, svg {
  height: auto;
  vertical-align: middle;
}

iframe {
  border: none;
}

// Utils classes

.overflow-hidden {
  overflow: hidden;
}

// Animations
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes jump {
  0% {
    transform: translate3d(0, -20%, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -20%, 0);
  }
}
