@import "core/variables";

.mainWrapper::before {
  background: (
    linear-gradient(180deg, rgba(0, 0, 0, 0) 71.53%, #000000 100%),
    linear-gradient(270deg, rgba(0, 0, 0, 0) 53.41%, #000000 100%)
  );
}

.content {
  justify-content: center;
  padding: 10rem 0;

  p {
    max-width: 524px;

    @include md {
      max-width: 440px;
    }
  }
}

.button {
  border-radius: 5px;
  padding: 0 38px;
  transition: 250ms background-color ease-in-out;

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.gradientText {
  @include gradientText;
}
