@import "core/variables";

.wrapper {
  overflow: hidden;
}

.container {
  padding: 9.375rem 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  @include md {
    padding: 0 0 3rem 0;
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  width: 55%;

  @include md {
    width: 100%;
    z-index: 1;
    text-align: center;
    align-items: center;
  }
}

.heading {
  margin-bottom: 0.86em;
}

.description {
  margin-bottom: 1.66em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  right: -20%;

  @include lg {
    right: -20%;
  }

  @include md {
    max-width: 650px;
    width: 150%;
    position: relative !important;
    inset: initial;
    margin-bottom: -15%;
  }
}

.underglow {
  width: 100%;
}

.productsImage {
  position: absolute;
  left: 20%;
  bottom: 15%;
  width: 110%;

  img {
    width: 100%;
  }

  @include md {
    width: 85%;
    left: initial;
    bottom: initial;
  }
}
