@import "core/variables";

.fullNameBlock {
  display: flex;
  flex-direction: row;
  gap: 50px;

  @include md {
    flex-direction: column;
    gap: 30px;
  }
}
