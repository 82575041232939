@import "core/variables";

.wrapper {
  padding-left: 1.5em;
}

.input {
  cursor: pointer;
  width: 1em;
  height: 1em;
  margin-top: 0.05em;
  margin-left: -1.5em;
  float: left;
  appearance: none;
  background: none;
  border: 1px solid $white;
  border-radius: 0.25em;

  &:checked {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E") no-repeat;
  }
}

.label {
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  color: $white;
}
