// SDK Color palette
$black: #000000;
$dark-grey: #101010;
$grey: #5B5B5B;
$white: #FFFFFF;
$red-error: #FF0000;
$accent-1: #995EFF;
$accent-2: #47B8F3;
$gradient: linear-gradient(162.63deg, $accent-1 0%, $accent-2 100%);


// Default screen breakpoints from Tailwind
$sm-width: 640px;
$md-width: 768px;
$lg-width: 1024px;
$xl-width: 1280px;
$xxl-width:	1536px;


$max-width: 1920px;
$max-content-width: 1326px;
$min-width: 320px;

$menu-height: 76px;
$menu-mobile-height: 66px;


@mixin roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

@mixin buttonAnimationScale($scale: 1.05) {
  transition: transform 150ms ease-out;
  will-change: transform;

  &:not(:disabled):hover {
    transform: scale($scale);
  }
}

@mixin gradientText {
  -webkit-text-fill-color: transparent;
  color: transparent;
  background: $gradient;
  -webkit-background-clip: text;
  background-clip: text;
}

@mixin button-outline {
  $button-border-radius: 72px;
  $button-outline-border: 2px solid #A6A6A6;

  display: inline-block;
  border: $button-outline-border;
  border-radius: $button-border-radius;
}

// TODO: Rename all mixins to camelCase and change it in all files
@mixin gradient-border($border-width, $background) {
  position: relative;
  background-clip: padding-box;
  border: solid $border-width transparent;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: -1;
    margin: -$border-width;
    border-radius: inherit;
    background: $background;
  }
}

@mixin transparentOutlineGradient($border-width, $background) {
  position: relative;
  background-color: transparent;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: $border-width;
    background: $background;
    -webkit-mask: linear-gradient(#FFFFFF 0 0) content-box, linear-gradient(#FFFFFF 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

@mixin decoration {
  position: absolute;
  pointer-events: none;
  user-select: none;
  z-index: -1;
}

@mixin opacityAnimation {
  transition: opacity 150ms ease-out;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

// Mixins for media queries with Tailwind default breakpoints
@mixin xxl {
  @media (max-width: $xxl-width) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $xl-width) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $lg-width) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md-width) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm-width) {
    @content;
  }
}

@mixin customMedia($width) {
  @media (max-width: $width) {
    @content;
  }
}
