.container {
  display: flex;
  visibility: visible;
  position: fixed;
  inset: 0;
  z-index: 101;
  align-items: center;
  justify-content: center;
}

.clickableBlock {
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  cursor: pointer;
}
