@import "app/javascript/core/variables";

$feature-desktop-width: 360px;

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 9.375rem 0;

  @include md {
    padding: 6rem 0;
  }
}

.title,
.subtitle {
  z-index: 1;
  text-align: center;
}

.title {
  margin-bottom: 0.555em;
}

.subtitle {
  margin-bottom: 50px;
}

.gpuFeaturesWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.gpuBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateY(-20%);
  width: 100%;
  aspect-ratio: 16 / 9;

  @include lg {
    max-width: 1300px;
    width: 180%;
    position: relative;
    margin-bottom: 60px;
    aspect-ratio: 16 / 4;
    transform: translateY(-30%);
  }

  @include md {
    width: 350%;
    aspect-ratio: 16 / 5;
    transform: translateY(-35%);
  }
}

.gpuItem {
  position: absolute;
  width: 100%;
}

.features {
  display: grid;
  z-index: 1;
  width: 100%;
  grid-template-columns: repeat(2, $feature-desktop-width);
  grid-column-gap: calc(100% - ($feature-desktop-width * 2));
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 50px;
  min-height: 415px;

  @include lg {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    height: 100%;
  }
}

.item {
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 25px;
  background-color: $dark-grey;
}

.itemIcon {
  width: 50px;
  height: 50px;
  display: flex;
}
