.howItWorks {
  width: 100%;
  border-radius: 0 10px 10px 10px;
  margin-top: 50px;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
