@import "core/variables";

$switch-width: 74px;
$switch-padding: 2px;
$switch-border-width: 2px;
$dot-size: 28px;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  width: $switch-width;
  min-width: $switch-width;
  height: 37px;
  border-radius: 25px;
  border: $switch-border-width solid $white;
  cursor: pointer;
  padding: $switch-padding;
  user-select: none;
  transition: 200ms ease-in-out;
  transition-property: background-color, border-color;

  .dot {
    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;
    background-color: $white;

    transition: transform 200ms ease-in-out;
    transform: translate3d(0, 0, 0);
  }

  &.active {
    background-color: $accent-1;
    border-color: $accent-1;

    .dot {
      $transform-x-value: $switch-width - $dot-size - (($switch-padding + $switch-border-width) * 2);

      transform: translate3d($transform-x-value, 0, 0);
    }
  }
}

.offText {
  position: absolute;
  right: 10px;
}

.onText {
  position: absolute;
  left: 10px;
}
