@import "../../core/variables";

.download-overlay {
  position: relative;
  background: #2A2C2B;
  width: 100%;
  max-width: 887px;
  padding: 48px 66px 60px;
  color: $white;

  &__close {
    position: absolute;
    top: 40px;
    right: 57px;
  }

  &__title {
    font-size: 42px;
    margin-bottom: 44px;
    font-weight: bold;
  }

  &__invitation {
    font-size: 24px;
    margin-bottom: 35px;
    max-width: 700px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
  }

  &__discord-button {
    @include buttonAnimationScale;

    display: flex;
    align-items: center;
    flex-direction: row;
    color: $white;
    background: $gradient;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    text-decoration: none;
    padding: 0 21px;
    height: 66px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 20px;

    &:hover {
      color: $white;
    }
  }

  &__discord-icon {
    width: 42px;
    height: 42px;
    margin-right: 9px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__social-network-links {
    font-size: 43px;
  }

  @include md {
    max-width: 325px;
    padding: 25px 20px 21px;

    &__close {
      top: 18px;
      right: 13px;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__title {
      font-size: 20px;
      margin-bottom: 17px;
    }

    &__invitation {
      font-size: 14px;
      margin-bottom: 24px;
      max-width: 239px;
    }

    &__discord-button {
      padding: 0 12px;
      height: 28px;
      font-size: 10px;
    }

    &__discord-icon {
      width: 21px;
      height: 21px;
      margin-right: 6px;
    }

    &__social-network-links {
      font-size: 22px;
    }
  }
}
