@import "app/javascript/core/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5rem 0 7rem;
}

.heading {
  text-align: center;
  margin-bottom: 1.39em;
}
