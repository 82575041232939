@import "app/javascript/core/variables";

.content {
  padding: 3.125rem 0 9.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;

  @include md {
    gap: 100px;
  }
}
