@import "core/variables";

.row {
  display: flex;
  flex-direction: row;
  gap: inherit;

  @include sm {
    flex-direction: column;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
  gap: inherit;
}

.checkbox {
  margin-bottom: 0.63em;
  font-size: 1rem;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
