@import "app/javascript/core/variables";

.footerContainer {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
}

.footerText {
  color: white;
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;

  @include md {
    font-size: 14px;
  }
}
