@import "core/variables";

.field {
  position: relative;
  width: 100%;

  .label {
    display: block;
    margin-bottom: 10px;
  }

  .error {
    position: absolute;
    top: 106%;
    right: 0;
    color: $red-error;
    font-size: 1.1rem;
  }

  &.small {
    .input {
      font-size: 1.15rem;
    }
  }
}
