@import "core/variables";

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 8rem 0;

  @include lg {
    padding: 6rem 0;
  }
}

.title {
  @include sm {
    text-align: center;
  }
}

.info {
  width: 100%;
  border-radius: 20px;
}

.steps {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }

  @include md {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
}

.step {
  padding: 30px;

  &.gradient {
    @include transparentOutlineGradient(
        1px,
        $gradient,
    );

    .badge {
      @include transparentOutlineGradient(
          1px,
          $gradient,
      );
    }
  }
}

.image {
  width: 100%;
}

.badge {
  @include transparentOutlineGradient(
    1px,
    linear-gradient(114.67deg, #FFFFFF 0.37%, #4F4F4F 100%)
  );

  display: inline-block;
  padding: 0.5em 1.77em;
  border-radius: 100px;
  margin: 2em 0 0.83em;
}
