@import "core/variables";

.content {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #808080;

  @include lg {
    padding: 6rem 0;
  }
}

.logo {
  margin-bottom: 20px;

  @include md {
    width: 280px;
  }
}

.subtitle {
  text-align: center;
  margin-bottom: 2.08em;
}

.players {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 100px;

  @include lg {
    flex-direction: column;
    max-width: 570px;
  }
}
