@import "core/variables";

.mainWrapper {
  position: relative;
  width: 100%;
  min-width: $min-width;

  &.first {
    padding-top: $menu-height;

    @include sm {
      padding-top: $menu-mobile-height;
    }
  }
}

.image {
  z-index: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  height: 100%;
  margin: 0 auto;
  max-width: $max-width;
  position: relative;
  z-index: 1;
}

.inner {
  margin: 0 auto;
  max-width: $max-content-width;
  width: 86%;

  @include xl {
    width: 93%;
  }
}
