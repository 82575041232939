@import "core/variables";

.block {
  display: flex;
  flex-direction: column;
}

.info {
  width: 100%;
  border-radius: 20px;
}

.features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
  margin-top: 60px;

  @include xl {
    grid-gap: 25px;
  }

  @include lg {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }

  @include md {
    grid-template-columns: 1fr;
  }
}

.feature {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 17px;
}

.title {
  margin-bottom: 0.7em;
}
