@import "app/javascript/core/variables";

.howItWorks {
  width: 100%;
  border-radius: 0 10px 10px 10px;
  margin-top: 50px;
}

.namPlugin {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 0 0;
  gap: 50px;

  img {
    border-radius: 5px;
  }

  p {
    padding: 2em 0;
  }

  @include lg {
    flex-direction: column;
    max-width: 600px;
    padding: 0 0 50px 0;

    img {
      width: 100%;
    }

    p {
      padding: 0 2em;
    }
  }

  @include md {
    gap: 30px;
    padding-bottom: 30px;

    p {
      padding: 0 1em;
    }
  }
}
