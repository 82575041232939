@import "core/variables";

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3.125rem;
  color: $white;
  font-size: 30px;
  position: relative;

  &.withBadge {
    .name {
      margin-right: 20%;
    }
  }

  @include sm {
    padding: 1.875rem 1.25rem;
  }
}

.name {
  margin-bottom: 1.335em;
}

.badge {
  background: $gradient;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1em;
  padding: 0.086em 0;
  min-width: 22%;
  text-align: center;
}

.description {
  font-size: 0.9em;
  opacity: 0.8;
}

.readmeLink {
  color: $white;
  font-weight: 500;
  font-size: 0.667em;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
}
