@import "core/variables";

.onSuccess {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  border-radius: 15px;
  background: $gradient;
  color: $white;
  font-size: 25px;
  font-weight: 700;

  img {
    width: 40px;
    height: auto;
    margin-right: 17px;
  }
}

@include lg {
  .onSuccess {
    height: 46px;
    margin-bottom: 50px;
    font-size: 22px;

    img {
      width: 30px;
      margin-right: 14px;
    }
  }
}

@media (max-height: 1110px) {
  .onSuccess {
    height: 46px;
    font-size: 22px;

    img {
      width: 30px;
      margin-right: 14px;
    }
  }
}

@media (max-width: 650px) {
  .onSuccess {
    height: 35px;
    font-size: 18px;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }
}
