@import "./app/javascript/core/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: $white;
  height: 100vh;
  min-height: 350px;
}
