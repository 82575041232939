@import "core/variables";

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recoveryButton {
  span {
    text-decoration: underline;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}
