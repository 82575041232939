@import "app/javascript/core/core";

.ql-editor {
  font-size: 36px;
  color: $white;

  h1 {
    font-size: 1em;
    margin-bottom: 18px;
  }

  h2 {
    font-size: 0.666em;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 0.555em;
    margin-bottom: 12px;
  }

  h4, h5, h6 {
    font-size: 0.444em;
    margin-bottom: 12px;
  }

  p {
    font-weight: 300;
    line-height: revert;
    font-size: 0.555em;
  }

  ul, ol {
    font-size: 0.555em;
    padding-left: 1.5em;
    font-weight: 300;
  }

  iframe {
    width: 100%;
    max-width: 640px;
    height: auto;
    aspect-ratio: 16 / 9;
    margin: auto;
    display: block;
  }

  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  a {
    color: $accent-1;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }

    &:visited {
      color: $grey;
    }
  }
}
