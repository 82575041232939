@import "core/variables";

.wrapper {
  background: url('shared/assets/profile-background.jpeg') no-repeat;
  background-position-x: center;
  background-size: auto 350px;

  @include sm {
    background: none;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15rem 0 8rem 0;
  gap: 40px;

  @include sm {
    padding: 5rem 0;
    gap: 25px;
  }
}

.subscribePanel {
  margin-bottom: 40px;

  @include sm {
    margin-bottom: 15px;
  }
}
