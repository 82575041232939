@import "core/variables";

$gap: 10px;
$icon-size: 25px;
$padding-horizontal: 20px;

.button {
  @include transparentOutlineGradient(1px, $white);

  background: transparent;
  color: $white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $gap;
  cursor: pointer;
  position: relative;
  border-radius: 5px;

  font-size: 24px;
  height: 48px;
  padding: 0 $padding-horizontal;

  &:hover:not(:disabled) {
    @include transparentOutlineGradient(1px, $gradient);
  }

  &:disabled {
    filter: brightness(0.7);
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $icon-size;
    height: $icon-size;
    position: relative;
  }

  .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $icon-size;
    height: $icon-size;
  }
}

.resizable {
  $padding-horizontal: 15px;
  $icon-size: 25px;

  font-size: 18px;
  height: 40px;
  padding: 0 $padding-horizontal;

  .icon {
    width: $icon-size;
    height: $icon-size;
  }
}

@include md {
  .resizable {
    $padding-horizontal: 10px;
    $icon-size: 15px;

    font-size: 14px;
    height: 30px;
    padding: 0 $padding-horizontal;

    .icon {
      width: $icon-size;
      height: $icon-size;
    }
  }
}

.visible, .hidden {
  transition: opacity 300ms ease, visibility 300ms ease;
  width: $icon-size;
  height: $icon-size;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.serviceText {
  visibility: hidden;
}

.text {
  position: absolute;
  text-align: center;
  transition: transform 300ms ease;
}

.hasUnhoveredIcon, .hasHoveredIcon  {
  .text {
    transform: translateX(calc($icon-size / 2));
  }
}
