@import "app/javascript/core/variables";

.wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  height: $menu-height;
  transition-duration: 100ms;
  transition-timing-function: ease-out;
  transition-property: background-color;
  background-color: rgba(0, 0, 0, 0.10);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

  @include md {
    height: $menu-mobile-height;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-size: 1.25rem;
}

.links {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  font-size: 1em;
  font-weight: 700;
  height: 2.5em;

  &:hover {
    color: $white;
  }
}

.navLinks {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 50px;

  @include lg {
    gap: 35px;
  }

  @include md {
    display: none;
  }
}

.mobileMenuButton {
  display: none;
  padding: 5px;
  transition: opacity 150ms ease-out;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 30px;

  @include md {
    display: flex;
  }

  @include sm {
    margin-right: 20px;
  }

  img {
    width: 24px;
  }
}

.progress {
  position: fixed;
  width: 100%;
  top: $menu-height;
  z-index: 10;

  @include md {
    top: $menu-mobile-height;
  }
}
