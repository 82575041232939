@import "core/variables";

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 9rem 0 3.125rem;

  @include lg {
    padding: 6rem 0;
  }
}

.title {
  @include sm {
    text-align: center;
  }
}

.info {
  width: 100%;
  border-radius: 20px;
}

.solutions {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  grid-gap: 30px;

  .solution {
    width: calc(50% - 15px);
  }

  .solution:nth-child(n+3) {
    width: calc(33.3333% - 20px);
  }

  @include xxl {
    .solution:nth-child(n+3) {
      width: calc(50% - 15px);
    }
  }

  @include lg {
    max-width: 600px;

    .solution,
    .solution:nth-child(n+3) {
      width: 100%;
    }
  }
}

.solution {
  @include opacityAnimation;
  
  position: relative;
  padding: 30px;
  border-radius: 20px;
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 455px;
  cursor: pointer;

  @include md {
    padding: 20px;
  }

  * {
    z-index: 1;
    position: relative;
  }

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 0;
    inset: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 39.22%, #000000 93.91%);
  }
}

.arrow {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 24px;
  height: auto;
}

.logo {
  width: 100%;
  max-width: 280px;
  margin-bottom: 20px;
}

.badge {
  @include transparentOutlineGradient(
    1px,
    linear-gradient(114.67deg, #FFFFFF 0.37%, #4F4F4F 100%)
  );

  display: inline-block;
  padding: 0.5em 1.77em;
  border-radius: 100px;
  margin: 2em 0 0.83em;
}
