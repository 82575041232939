@import "core/variables";

.content {
  padding: 8rem 0;

  @include lg {
    padding: 6rem 0;
  }
}

.title {
  text-align: center;
  margin-bottom: 0.5555em;
}

.subtitle {
  text-align: center;
  margin-bottom: 2.08em;
}

.info {
  width: 100%;
  border-radius: 20px;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  margin-top: 60px;
  width: 100%;

  @include xl {
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    gap: 45px;
    margin-top: 45px;
  }

  @include md {
    grid-template-columns: 1fr;
  }
}

.feature {
  height: 100%;
  gap: 17px;

  @include md {
    padding: 20px
  }
}

.featureTitle {
  @include gradientText;

  margin-bottom: 30px;

  span {
    font-size: 75px;
    font-weight: 700;

    @include xxl {
      font-size: 62px;
    }
  }
}
