@import "core/variables";

.container {
  @include transparentOutlineGradient(
      1px,
      linear-gradient(114.93deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 79.68%)
  );

  background-image: linear-gradient(114.67deg, rgba(255, 255, 255, 0.1) 0.37%, rgba(79, 79, 79, 0.1) 100%);
  border-radius: 16px;
  overflow: hidden;
  padding: 30px;

  &.accent {
    @include transparentOutlineGradient(
        1px,
        $gradient,
    );
  }
}
